import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

/**
 * The basic return structure we get from the API
 */
export interface RunResult
{
	status: number;				// 0 indicates all is fine
	msg: string;
	error: string;
	rows?: Array<any>;			// the rows of data if that applies
	next?: string;
	prev?: string;
}

/**
 * An order that appears in a list of orders
 */
export interface Order
{
	order_count: number;
	order_id: string;
	order_status: string;
	order_date: string;
	delivery_time: string;
	delivery_address: string;
	order_store_count: string;
	order_item_count: string;
	order_value: string;

	order_store_count_formatted?: string;
	order_item_count_formatted?: string;
	order_value_formatted?: string;
}

/**
 * An item that appears on an order
 */
export interface OrderItem
{
	order_item_id: string;
	store_name: string;
	product_category_name: string;
	product_name: string;
	brand: string;
	barcode: string;
	price: string;
	quantity: string;
	image_link: string;

	price_formatted?: string;
	quantity_formatted?: string;
	checked?: boolean;
}

@Injectable( {
	providedIn: "root"
} )
export class RunService
{
	constructor(
		private http: HttpClient
	)
	{
	}

	/**
	 * This will log the runner in.
	 *
	 * It returns a status of 0 if the login is successful and 1 if not.
	 *
	 * @param login the runner login
	 * @param password the runner password
	 */
	runner_login ( login: string, password: string ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/runner_login", { login: login, password: password } )
			.toPromise();
	}

	/**
	 * This will log the runner out.
	 *
	 * It returns a status of 0 if the logout is successful and 1 if not, e.g. the
	 * runner is already logged out.
	 */
	runner_logout (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/runner_logout" )
			.toPromise();
	}

	/**
	 * This pings the server.
	 *
	 * This will also update the authentication token and can be used to refresh the token
	 * or to check if the server is up.
	 */
	runner_ping (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/runner_ping" )
			.toPromise();
	}

	/**
	 * This requests a runner password reset link.
	 */
	runner_reset_link ( email: string ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/runner_reset_link", { email: email } )
			.toPromise();
	}

	/**
	 * Reads the runner.
	 *
	 * This will read and return runner details of the logged in runner.
	 */
	runner_read (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/runner_read" )
			.toPromise();
	}

	/**
	 * Updates the runner.
	 *
	 * This will update the runner details of the logged in runner.
	 */
	runner_update ( description: string ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/runner_update", { description: description } )
			.toPromise();
	}

	/**
	 * Read a list of available orders.
	 */
	orders_list (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/orders_list" )
			.toPromise();
	}

	/**
	 * Read a list of available driver orders.
	 */
	driver_orders_list (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/driver_orders_list" )
			.toPromise();
	}

	/**
	 * Read a list of accepted or otherwise my orders.
	 */
	orders_list_mine (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/orders_list_mine" )
			.toPromise();
	}

	/**
	 * Read a list of accepted or otherwise my orders.
	 */
	driver_orders_list_mine (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/driver_orders_list_mine" )
			.toPromise();
	}

	/**
	 * Read a list of my delivered orders.
	 */
	orders_list_delivered (): Promise<RunResult>
	{
		return this.http
			.get<RunResult>( "/run-api/orders_list_delivered" )
			.toPromise();
	}

	/**
	 * Read the order details of a specific order
	 */
	order_details ( order_id ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/order_details", { order_id: order_id } )
			.toPromise();
	}

	/**
	 * Accept an order
	 */
	order_accept ( order_id ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/order_accept", { order_id: order_id } )
			.toPromise();
	}

	/**
	 * Move an order backwards
	 */
	order_bak ( order_id ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/order_bak", { order_id: order_id } )
			.toPromise();
	}

	/**
	 * Move an order forwards
	 */
	order_fwd ( order_id, order_amount, order_refund ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/order_fwd", { order_id: order_id, order_amount:order_amount, order_refund:order_refund } )
			.toPromise();
	}

	/**
	 * Read the order items details of a specific order
	 */
	order_items_details ( order_id ): Promise<RunResult>
	{
		return this.http
			.post<RunResult>( "/run-api/order_items_details", { order_id: order_id } )
			.toPromise();
	}

	/**
	 * Show a general message
	 */
	show_message ( toastController: ToastController, message: string, dismiss: () => any )
	{
		toastController
			.create( { message: message, position: "middle", showCloseButton: true, color: "primary" } )
			.then( ( toast: HTMLIonToastElement ) =>
			{
				toast.present();
				toast.onDidDismiss().finally( () =>
				{
					if ( dismiss )
					{
						dismiss();
					}
				} );
			} );
	}

	/**
	 * Handle a warning message with a callback to call when it is dismissed
	 */
	show_warning ( toastController: ToastController, warning: string, dismiss: () => any )
	{
		toastController
			.create( { message: warning, position: "middle", showCloseButton: true, color: "warning" } )
			.then( ( toast: HTMLIonToastElement ) =>
			{
				toast.present();
				toast.onDidDismiss().finally( () =>
				{
					if ( dismiss )
					{
						dismiss();
					}
				} );
			} );
	}

	/**
	 * Handle an error, typically an exception, with a callback to call when it is dismissed
	 */
	show_error ( toastController: ToastController, reason: any, dismiss: () => any )
	{
		console.error( reason );
		toastController
			.create( { message: "System error - please try again later", position: "middle", showCloseButton: true, color: "danger" } )
			.then( ( toast: HTMLIonToastElement ) =>
			{
				toast.present();
				toast.onDidDismiss().finally( () =>
				{
					if ( dismiss )
					{
						dismiss();
					}
				} );
			} );
	}

	/** Return an integer amount suitable for display */
	format_integer ( amount )
	{
		if ( amount === undefined || amount === null )
		{
			return "";
		}

		if ( typeof amount !== "number" )
		{
			amount = Number.parseInt( amount );
		}

		if ( Number.isNaN( amount ) )
		{
			return "N/A";
		}

		// Add thousands separator
		let s = amount.toFixed( 0 );

		const pattern = /(\d)(\d\d\d)(\s|$)/;

		while ( pattern.test( s ) )
		{
			s = s.replace( pattern, "$1 $2$3" );
		}

		// Done
		return s;
	}

	/** Return a monetary amount suitable for display */
	format_money ( amount )
	{
		if ( amount === undefined || amount === null )
		{
			return "";
		}

		if ( typeof amount !== "number" )
		{
			amount = Number.parseFloat( amount );
		}

		if ( Number.isNaN( amount ) )
		{
			return "N/A";
		}

		// Add thousands separator
		let s = ( + amount ).toFixed( 2 );

		const pattern = /(\d)(\d\d\d)(\.|\s)/;

		while ( pattern.test( s ) )
		{
			s = s.replace( pattern, "$1 $2$3" );
		}

		// Add currency symbol
		return "R " + s;
	}
}
